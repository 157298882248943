import { FC, useMemo } from 'react';

import { useRecoilState } from 'recoil';

import { ShopOrderForSellerAccount } from '@shared/api/shopOrder/shopOrder.interface';
import CheckboxFilter from '@shared/components/CheckboxFilter';
import { getAccountDate } from '@shared/utils/dateUtil';
import { formatDate } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';

import { SellerAccountDetaiModalButton } from '../SellerAccountDetailModal/SellerAccountDetailModalButton';
import { sellerAccountBugoBrandFilterAtom } from './accountContent.atom';
import { SellerAccountButton } from './SellerAccountButton';

//* 이번달 및 다음달 정산 받아야하는 내용 표시

interface Props {
  shopOrderList: ShopOrderForSellerAccount[];
}

type AccountData = {
  id: string;
  month: number;
  accountDate: Date;
  accountPrice: number;
  accountCount: number;
  rebatePrice: number;
  shopOrderList: ShopOrderForSellerAccount[];
};

const columns: TableColumnsType<AccountData> = [
  {
    title: '정산기간',
    dataIndex: 'month',
    key: 'month',
    align: 'center',
    render: (month: number) => {
      const now = new Date();
      return (
        <p
          className={`px-6 py-1 text-base font-medium ${
            now.getTime() > getAccountDate(month).getTime() ? 'theme-text-warn-03' : ''
          }`}
        >
          {month}월
        </p>
      );
    },
  },
  {
    title: '정산지급일',
    dataIndex: 'accountDate',
    key: 'accountDate',
    align: 'center',
    render: (accountDate: Date) => {
      const now = new Date();
      if (accountDate.getTime() < now.getTime()) {
        return (
          <p className="break-keep px-6 py-1 text-base font-medium theme-text-warn-03">
            {formatDate(accountDate)} (미지급)
          </p>
        );
      } else {
        return (
          <p className="px-6 py-1 text-base font-medium">{formatDate(accountDate)}</p>
        );
      }
    },
  },
  {
    title: '총 판매건수',
    dataIndex: 'accountCount',
    key: 'accountCount',
    align: 'center',
    render: (accountCount: number, record) => {
      const now = new Date();
      return (
        <p
          className={`px-6 py-1 text-base font-medium ${
            now.getTime() > getAccountDate(record.month).getTime()
              ? 'theme-text-warn-03'
              : ''
          }`}
        >
          {accountCount.toLocaleString()}개
        </p>
      );
    },
  },
  {
    title: '화환 정산금',
    dataIndex: 'accountPrice',
    key: 'accountPrice',
    align: 'center',
    render: (accountPrice: number, record) => {
      const now = new Date();
      return (
        <p
          className={`px-6 py-1 text-base font-medium ${
            now.getTime() > getAccountDate(record.month).getTime()
              ? 'theme-text-warn-03'
              : ''
          }`}
        >
          {' '}
          {accountPrice.toLocaleString()}원
        </p>
      );
    },
  },
  {
    title: '상조 정산금',
    dataIndex: 'rebatePrice',
    key: 'rebatePrice',
    align: 'center',
    render: (rebatePrice: number, record) => {
      const now = new Date();
      return (
        <p
          className={`px-6 py-1 text-base font-medium ${
            now.getTime() > getAccountDate(record.month).getTime()
              ? 'theme-text-warn-03'
              : ''
          }`}
        >
          {rebatePrice.toLocaleString()}원
        </p>
      );
    },
  },
  {
    title: '합계',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      const now = new Date();
      return (
        <p
          className={`px-6 py-1 text-base font-medium ${
            now.getTime() > getAccountDate(record.month).getTime()
              ? 'theme-text-warn-03'
              : ''
          }`}
        >
          {(record.accountPrice + record.rebatePrice).toLocaleString()}원
        </p>
      );
    },
  },
  {
    title: '세부내역보기',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      return (
        <div className="center-box">
          <SellerAccountDetaiModalButton shopOrderList={record.shopOrderList} />
        </div>
      );
    },
  },
  {
    title: '정산',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      return (
        <div className="center-box">
          <SellerAccountButton shopOrderList={record.shopOrderList} />
        </div>
      );
    },
  },
];

export const PresentAccountContent: FC<Props> = function PresentAccountContent({
  shopOrderList: needAccountOrderList,
}: Props) {
  // BugoBrand Filtering
  const [selectedBugoBrandsTypes, setSelectedBugoBrandsTypes] = useRecoilState(
    sellerAccountBugoBrandFilterAtom,
  );

  // 정산 필요내역들

  const monthlyNeedAccountOrders: { [month: number]: ShopOrderForSellerAccount[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyNeedAccountOrders[i] = [];
  }

  needAccountOrderList.map((order) => {
    if (order.paymentDetail.requestedAt) {
      const month = order.paymentDetail.requestedAt.getMonth() + 1;
      monthlyNeedAccountOrders[month]?.push(order);
    }
  });

  const accountOrderList2 = _.map(monthlyNeedAccountOrders, (orderList, index) => {
    if (orderList.length > 0) {
      return {
        month: Number(index),
        orderList: orderList,
      };
    } else return null;
  }).filter((item) => {
    return item;
  });

  const filteredAccounOrderList2 = (
    accountOrderList2 as { month: number; orderList: ShopOrderForSellerAccount[] }[]
  ).map((item) => {
    return {
      month: item.month,
      orderList: item.orderList.filter((order) => {
        return selectedBugoBrandsTypes[
          order.bugo?.bugoBrand?.bugoAgency?.nickName ?? 'etc'
        ];
      }),
    };
  });

  const tableData: AccountData[] = useMemo(() => {
    return _.map(filteredAccounOrderList2, (orderObj, index) => {
      return {
        id: String(index),
        month: orderObj.month,
        accountDate: getAccountDate(orderObj.month),
        accountCount: orderObj.orderList?.length ?? 0,
        accountPrice:
          orderObj.orderList?.reduce((accu, prev) => {
            return accu + prev.orderDetail.shopItemEmbed.priceWhole;
          }, 0) ?? 0,
        rebatePrice:
          orderObj.orderList?.reduce((accu, prev) => {
            return accu + prev.orderDetail.shopItemEmbed.priceRebate;
          }, 0) ?? 0,
        shopOrderList: orderObj.orderList,
      };
    });
  }, [filteredAccounOrderList2]);

  const presentMonthAccount = useMemo(() => {
    // shopOrderList중 requestAt 기준 해당 월까지

    return (
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          hideOnSinglePage: true,
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
    );
  }, [tableData]);

  // 다음달 정산내역

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4 border px-4 text-sm theme-bg-1 theme-border-1">
        <div className={`center-box self-stretch bg-opacity-40 px-2 font-bold`}>상조</div>
        <div className="flex flex-1 items-center justify-around space-x-4 py-1 pr-2">
          <CheckboxFilter
            filter={selectedBugoBrandsTypes}
            setFilter={(checked) => setSelectedBugoBrandsTypes(checked)}
          />
        </div>
      </div>
      <p className="pl-4 text-base font-medium">정산예정내역</p>
      {presentMonthAccount}
    </div>
  );
};
