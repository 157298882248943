import { useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole, PaymentState, UserRole } from '@shared/types';

import { fetcher, queryString } from './../../hooks/recoil-query';
import {
  workerUserAgencyAdminDataAtomFamily,
  workerUserAgencyAdminStatusAtomFamily,
  workerUserDataAtomFamily,
  workerUserListByBugoBrandDataAtomFamily,
  workerUserListByBugoBrandLightDataAtomFamily,
  workerUserListByBugoBrandLightStatusAtomFamily,
  workerUserListByBugoBrandStatusAtomFamily,
  workerUserStatusAtomFamily,
} from './user.atom';

//* bugoBrand 별로 해당 지도사님들을 불러오는 hook Url
export const workerUserByBugoBrandHookUrl = (
  bugoRole: LowerRole,
  bugoBrandId: string,
) => {
  return `/${bugoRole}/user?${queryString({
    filter: {
      bugoBrand: bugoBrandId,
      roles: UserRole.BugoAgencyWorker,
    },
    populate: [
      {
        path: 'bugoAgencyWorkerDetail.fevents',
        populate: [
          {
            path: 'shopOrders',
          },
          {
            path: 'funeralHomeInfo',
          },
          {
            path: 'memberOrderList',
            populate: {
              path: 'bugo',
            },
          },
        ],
      },
    ],
  })}`;
};

//* bugoBrand 별로 해당 지도사님들을 불러오는 hook
export const useWorkerUserByBugoBrandHook = (bugoBrandId: string) => {
  const { bugoRole, isBugoAgencyAdmin, isBugoAgencyBranchAdmin, isAdmin } = useAuth();
  useRecoilQuery(
    workerUserListByBugoBrandStatusAtomFamily(bugoBrandId),
    workerUserListByBugoBrandDataAtomFamily(bugoBrandId),
    workerUserByBugoBrandHookUrl(bugoRole(), bugoBrandId),
    fetcher,
    !(isBugoAgencyAdmin() || isBugoAgencyBranchAdmin() || isAdmin()),
  );
};

//* bugoBrand 별로 해당 지도사님들을 불러오는 hook Url (refactoring)
export const workerUserAgencyAdminHookUrl = (
  bugoRole: LowerRole,
  bugoBrandId: string,
) => {
  return `/${bugoRole}/user?${queryString({
    select: ['_id', 'info', 'bugoAgencyWorkerDetail'],
    filter: {
      bugoBrand: bugoBrandId,
      roles: UserRole.BugoAgencyWorker,
    },
    populate: [
      {
        path: 'bugoAgencyWorkerDetail.fevents',
        select: ['_id', 'shopOrders', 'memberOrderList', 'bugos'],
        populate: [
          {
            path: 'shopOrders',
            match: {
              'paymentDetail.status': {
                $in: [PaymentState.PAID, PaymentState.CONFIRMED],
              },
            },
            select: ['_id'],
          },
          {
            path: 'bugos',
            select: ['_id', 'lastSentPhoneNumber'],
          },
          // {
          //   path: 'memberOrderList',
          //   select: ['_id', 'bugo'],
          //   populate: {
          //     path: 'bugo',
          //     select: ['_id', 'lastSentPhoneNumber'],
          //   },
          // },
        ],
      },
    ],
  })}`;
};

//* bugoBrand 별로 해당 지도사님들을 불러오는 hook (refactoring)
export const useWorkerUserAgencyAdminHook = (bugoBrandId: string, disabled?: boolean) => {
  const { bugoRole, isBugoAgencyAdmin, isBugoAgencyBranchAdmin, isAdmin } = useAuth();
  useRecoilQuery(
    workerUserAgencyAdminStatusAtomFamily(bugoBrandId),
    workerUserAgencyAdminDataAtomFamily(bugoBrandId),
    workerUserAgencyAdminHookUrl(bugoRole(), bugoBrandId),
    fetcher,
    !(isBugoAgencyAdmin() || isBugoAgencyBranchAdmin() || isAdmin() || !disabled),
  );
};

//* userId로 지도사을 불러오는 url
export const workerUserByIdHookUrl = (bugoRole: LowerRole, userId: string) => {
  return `/${bugoRole}/user/${userId}?${queryString({
    filter: {
      roles: UserRole.BugoAgencyWorker,
    },
    populate: [
      {
        path: 'bugoAgencyWorkerDetail.fevents',
        options: {
          sort: { createdAt: -1 },
        },
        populate: [
          {
            path: 'shopOrders',
          },
          {
            path: 'funeralHomeInfo',
          },
        ],
      },
    ],
  })}`;
};

//* userId로 지도사을 불러오는 hook
export const useWorkerUserByIdHook = (userId: string) => {
  const { bugoRole, isBugoAgencyAdmin, isBugoAgencyBranchAdmin, isAdmin } = useAuth();

  useRecoilQuery(
    workerUserStatusAtomFamily(userId),
    workerUserDataAtomFamily(userId),
    workerUserByIdHookUrl(bugoRole(), userId),
    fetcher,
    !(isBugoAgencyAdmin() || isBugoAgencyBranchAdmin() || isAdmin()),
  );
};

//* 지도사 등록현황을 가져오기 위한 좀더 가벼운 hook
export const workerUserByBugoBrandLightHookUrl = (
  bugoRole: LowerRole,
  bugoBrandId: string,
) => {
  return `/${bugoRole}/user?${queryString({
    filter: {
      bugoBrand: bugoBrandId,
      roles: UserRole.BugoAgencyWorker,
      isDeleted: { $ne: true },
    },
  })}`;
};
export const useWorkerUserByBugoBrandLightHook = (bugoBrandId: string) => {
  const { bugoRole, isBugoAgencyAdmin, isBugoAgencyBranchAdmin, isAdmin } = useAuth();
  useRecoilQuery(
    workerUserListByBugoBrandLightStatusAtomFamily(bugoBrandId),
    workerUserListByBugoBrandLightDataAtomFamily(bugoBrandId),
    workerUserByBugoBrandLightHookUrl(bugoRole(), bugoBrandId),
    fetcher,
    !(isBugoAgencyAdmin() || isBugoAgencyBranchAdmin() || isAdmin()),
  );
};
