import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { BugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { ShopOrderAgencyAdmin } from '@shared/api/shopOrder/shopOrder.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { getRebateDate } from '@shared/utils/dateUtil';
import { formatDate } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';

import { AgencyAdminAccountDetailModalButton } from '../AgencyAdminAccountDetail/AgencyAdminAccountDetailModalButton';
import { agencyAdminAccountTeamNameFilterAtom } from './AgencyAdminAccount.atom';

//* 연간 정산 완료 및 예정 내역
interface Props {
  shopOrderList: ShopOrderAgencyAdmin[];
}
type AccountData = {
  id: string;
  month: number;
  rebatedDate?: Date;
  rebateAgencyPrice: number;
  rebateWorkerPrice: number;
  rebateCount: number;
  willRebateCount: number;
  willRebatePrice: number;
  willRebateWorkerPrice: number;
  rebatedOrderList: ShopOrderAgencyAdmin[];
  willRebatedOrderList?: ShopOrderAgencyAdmin[];
};

const columns: (bugoBrand?: BugoBrand) => TableColumnsType<AccountData> = (
  bugoBrand?: BugoBrand,
) => {
  if (bugoBrand?.bugoAgency?.shareRebateWithWorker) {
    return [
      {
        title: '정산기간',
        dataIndex: 'month',
        key: 'month',
        align: 'center',
        render: (month: number) => {
          return <p className="px-6 py-1 text-base font-medium">{month}월</p>;
        },
      },
      {
        title: '정산일',
        dataIndex: 'rebatedDate',
        key: 'rebatedDate',
        align: 'center',
        render: (_, record) => {
          if (record.rebatedDate) {
            return (
              <p className="px-6 py-1 text-base font-medium text-green-600">
                {formatDate(record.rebatedDate)} 완료
              </p>
            );
          } else {
            const rebatedDate = getRebateDate(record.month);
            const now = new Date();
            if (now.getTime() > rebatedDate.getTime()) {
              return (
                <p className="px-6 py-1 text-base font-medium theme-text-warn-03">
                  {formatDate(getRebateDate(record.month))} 미지급
                </p>
              );
            } else {
              return (
                <p
                  className={`px-6 py-1 text-base font-medium ${
                    record.willRebateCount > 0 ? 'theme-text-main' : ''
                  }`}
                >
                  {formatDate(getRebateDate(record.month))} 예정
                </p>
              );
            }
          }
        },
      },
      {
        title: '총 판매건수',
        dataIndex: 'rebateCount',
        key: 'rebateCount',
        align: 'center',
        render: (rebateCount: number, record) => {
          const now = new Date();
          if (rebateCount === 0 && record.willRebateCount) {
            return (
              <p
                className={`px-6 py-1 text-base font-medium ${
                  now.getTime() > getRebateDate(record.month).getTime()
                    ? 'theme-text-warn-03'
                    : 'theme-text-main'
                }`}
              >
                {record.willRebateCount.toLocaleString()}개
              </p>
            );
          } else {
            return (
              <p className="px-6 py-1 text-base">
                {rebateCount === 0 ? '-' : rebateCount.toLocaleString() + '개'}
              </p>
            );
          }
        },
      },
      {
        title: bugoBrand.bugoAgency.nickName + ' 정산금',
        dataIndex: 'rebateAgencyPrice',
        key: 'rebateAgencyPrice',
        align: 'center',
        render: (rebateAgencyPrice: number, record) => {
          const now = new Date();
          if (rebateAgencyPrice === 0 && record.willRebatePrice) {
            return (
              <p
                className={`px-6 py-1 text-base font-medium ${
                  now.getTime() > getRebateDate(record.month).getTime()
                    ? 'theme-text-warn-03'
                    : 'theme-text-main'
                }`}
              >
                {record.willRebatePrice.toLocaleString()}원
              </p>
            );
          } else {
            return (
              <p className="px-6 py-1 text-base font-medium">
                {rebateAgencyPrice === 0
                  ? '-'
                  : rebateAgencyPrice.toLocaleString() + '원'}
              </p>
            );
          }
        },
      },
      {
        title: '지도사 정산금',
        dataIndex: 'rebateWorkerPrice',
        key: 'rebateWorkerPrice',
        align: 'center',
        render: (rebateWorkerPrice: number, record) => {
          const now = new Date();
          if (rebateWorkerPrice === 0 && record.willRebateWorkerPrice) {
            return (
              <p
                className={`px-6 py-1 text-base font-medium ${
                  now.getTime() > getRebateDate(record.month).getTime()
                    ? 'theme-text-warn-03'
                    : 'theme-text-main'
                }`}
              >
                {record.willRebateWorkerPrice?.toLocaleString()}원
              </p>
            );
          } else {
            return (
              <p className="px-6 py-1 text-base font-medium">
                {rebateWorkerPrice === 0
                  ? '-'
                  : rebateWorkerPrice.toLocaleString() + '원'}
              </p>
            );
          }
        },
      },
      {
        title: '합계',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, record) => {
          const now = new Date();
          if (record.rebateAgencyPrice === 0 && record.willRebatePrice) {
            return (
              <p
                className={`px-6 py-1 text-base font-medium ${
                  now.getTime() > getRebateDate(record.month).getTime()
                    ? 'theme-text-warn-03'
                    : 'theme-text-main'
                }`}
              >
                {(record.willRebatePrice + record.willRebateWorkerPrice).toLocaleString()}
                원
              </p>
            );
          } else {
            return (
              <p className="px-6 py-1 text-base font-medium">
                {record.rebateAgencyPrice + record.rebateWorkerPrice === 0
                  ? '-'
                  : (
                      record.rebateAgencyPrice + record.rebateWorkerPrice
                    ).toLocaleString() + '원'}
              </p>
            );
          }
        },
      },
      {
        title: '세부내역보기',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, record) => {
          if (record.rebatedOrderList.length === 0 && record.willRebatedOrderList) {
            return (
              <div className="center-box">
                <AgencyAdminAccountDetailModalButton
                  shopOrderList={record.willRebatedOrderList}
                />
              </div>
            );
          } else {
            return (
              <div className="center-box">
                <AgencyAdminAccountDetailModalButton
                  shopOrderList={record.rebatedOrderList}
                />
              </div>
            );
          }
        },
      },
    ];
  } else {
    return [
      {
        title: '정산기간',
        dataIndex: 'month',
        key: 'month',
        align: 'center',
        render: (month: number) => {
          return <p className="px-6 py-1 text-base font-medium">{month}월</p>;
        },
      },
      {
        title: '정산일',
        dataIndex: 'rebatedDate',
        key: 'rebatedDate',
        align: 'center',
        render: (_, record) => {
          if (record.rebatedDate) {
            return (
              <p className="px-6 py-1 text-base font-medium text-green-600">
                {formatDate(record.rebatedDate)} 완료
              </p>
            );
          } else {
            const rebatedDate = getRebateDate(record.month);
            const now = new Date();
            if (now.getTime() > rebatedDate.getTime()) {
              return (
                <p className="px-6 py-1 text-base font-medium theme-text-warn-03">
                  {formatDate(getRebateDate(record.month))} 미지급
                </p>
              );
            } else {
              return (
                <p
                  className={`px-6 py-1 text-base font-medium ${
                    record.willRebateCount > 0 ? 'theme-text-main' : ''
                  }`}
                >
                  {formatDate(getRebateDate(record.month))} 예정
                </p>
              );
            }
          }
        },
      },
      {
        title: '총 판매건수',
        dataIndex: 'rebateCount',
        key: 'rebateCount',
        align: 'center',
        render: (rebateCount: number, record) => {
          const now = new Date();
          if (rebateCount === 0 && record.willRebateCount) {
            return (
              <p
                className={`px-6 py-1 text-base font-medium ${
                  now.getTime() > getRebateDate(record.month).getTime()
                    ? 'theme-text-warn-03'
                    : 'theme-text-main'
                }`}
              >
                {record.willRebateCount.toLocaleString()}개
              </p>
            );
          } else {
            return (
              <p className="px-6 py-1 text-base">
                {rebateCount === 0 ? '-' : rebateCount.toLocaleString() + '개'}
              </p>
            );
          }
        },
      },
      {
        title: bugoBrand?.bugoAgency?.nickName ?? '' + ' 정산금',
        dataIndex: 'rebateAgencyPrice',
        key: 'rebateAgencyPrice',
        align: 'center',
        render: (rebateAgencyPrice: number, record) => {
          const now = new Date();
          if (rebateAgencyPrice === 0 && record.willRebatePrice) {
            return (
              <p
                className={`px-6 py-1 text-base font-medium ${
                  now.getTime() > getRebateDate(record.month).getTime()
                    ? 'theme-text-warn-03'
                    : 'theme-text-main'
                }`}
              >
                {record.willRebatePrice.toLocaleString()}원
              </p>
            );
          } else {
            return (
              <p className="px-6 py-1 text-base font-medium">
                {rebateAgencyPrice === 0
                  ? '-'
                  : rebateAgencyPrice.toLocaleString() + '원'}
              </p>
            );
          }
        },
      },

      {
        title: '세부내역보기',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, record) => {
          if (record.rebatedOrderList.length === 0 && record.willRebatedOrderList) {
            return (
              <div className="center-box">
                <AgencyAdminAccountDetailModalButton
                  shopOrderList={record.willRebatedOrderList}
                />
              </div>
            );
          } else {
            return (
              <div className="center-box">
                <AgencyAdminAccountDetailModalButton
                  shopOrderList={record.rebatedOrderList}
                />
              </div>
            );
          }
        },
      },
    ];
  }
};

export const AnnualAccountedTable: FC<Props> = function AnnualAccountedTable({
  shopOrderList,
}: Props) {
  //연도 설정
  const { userProfile } = useAuth();
  const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];

  const now = new Date();
  //TODO: year 설정
  const [selectYear, setSelectYear] = useState<number>(now.getFullYear());

  // 1. 월별로 주문을 분류할 객체를 만듭니다.
  const monthlyAccountedOrders: { [month: number]: ShopOrderAgencyAdmin[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyAccountedOrders[i] = [];
  }

  const monthlyWillAccountOrders: { [month: number]: ShopOrderAgencyAdmin[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyWillAccountOrders[i] = [];
  }

  // 2. order 배열을 순회하면서, 각 주문의 월을 결정합니다.
  shopOrderList.map((order) => {
    if (order.paymentDetail.isRebated && order.paymentDetail.requestedAt) {
      const month = order.paymentDetail.requestedAt?.getMonth() + 1;
      monthlyAccountedOrders[month]?.push(order);
    } else {
      if (order.paymentDetail.requestedAt) {
        const month = order.paymentDetail.requestedAt.getMonth() + 1;
        monthlyWillAccountOrders[month]?.push(order);
      }
    }
  });

  const resultArray = _.map(monthlyAccountedOrders, (orderList, index) => {
    return {
      rebatedOrderList: orderList,
      willRebatedOrderList: monthlyWillAccountOrders[Number(index)],
    };
  });

  // TeamName Filtering
  const selectedTeamNames = useRecoilValue(agencyAdminAccountTeamNameFilterAtom);

  const filteredResultArray = resultArray.map((item) => {
    if (selectedTeamNames) {
      return {
        rebatedOrderList: item.rebatedOrderList.filter((order) => {
          return selectedTeamNames[
            order?.fevent?.user?.bugoAgencyWorkerDetail?.teamName ?? 'etc'
          ];
        }),
        willRebatedOrderList: item.willRebatedOrderList?.filter((order) => {
          return selectedTeamNames[
            order?.fevent?.user?.bugoAgencyWorkerDetail?.teamName ?? 'etc'
          ];
        }),
      };
    } else {
      return {
        rebatedOrderList: item.rebatedOrderList,
        willRebatedOrderList: item.willRebatedOrderList,
      };
    }
  });

  const tableData: AccountData[] = useMemo(() => {
    const now = new Date();
    const annualData = _.map(filteredResultArray, (item, index) => {
      return {
        id: `accountTable-${index}`,
        month: index + 1,
        rebatedDate:
          !(item.willRebatedOrderList && item.willRebatedOrderList.length > 0) &&
          item.rebatedOrderList.length === 0 &&
          now.getTime() > getRebateDate(index + 1).getTime()
            ? getRebateDate(index + 1)
            : item.rebatedOrderList[0]?.paymentDetail.accountedDate,
        rebateCount: item.rebatedOrderList.length,
        willRebateCount: item.willRebatedOrderList?.length ?? 0,
        rebateAgencyPrice:
          item.rebatedOrderList.reduce((accu, prev) => {
            return (
              accu +
              (prev.orderDetail.shopItemEmbed.priceRebateAgency ??
                prev.orderDetail.shopItemEmbed.priceRebate)
            );
          }, 0) ?? 0,
        rebateWorkerPrice:
          item.rebatedOrderList.reduce((accu, prev) => {
            return accu + (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0);
          }, 0) ?? 0,
        willRebatePrice:
          item.willRebatedOrderList?.reduce((accu, prev) => {
            return accu + prev.orderDetail.shopItemEmbed.priceRebateAgency;
          }, 0) ?? 0,
        willRebateWorkerPrice:
          item.willRebatedOrderList?.reduce((accu, prev) => {
            return accu + (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0);
          }, 0) ?? 0,
        rebatedOrderList: item.rebatedOrderList,
        willRebatedOrderList: item.willRebatedOrderList,
      };
    });
    return annualData;
  }, [filteredResultArray]);

  const filteredShopOrder = shopOrderList.filter((order) => {
    if (selectedTeamNames) {
      return selectedTeamNames[
        order?.fevent?.user?.bugoAgencyWorkerDetail?.teamName ?? 'etc'
      ];
    } else return true;
  });

  return (
    <div className="space-y-4">
      <p className="pl-4 text-base font-medium">{selectYear}년 정산완료 내역</p>
      <Table
        className="w-full"
        columns={columns(bugoBrand)}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          defaultPageSize: 13,
          hideOnSinglePage: true,
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
      <div className="button-rectangle flex cursor-default items-center justify-evenly gap-4 rounded-lg text-base theme-text-4 theme-bg-12">
        <p>합산</p>
        <p>{filteredShopOrder.length}건</p>
        <p>
          {filteredShopOrder
            .reduce((accu, prev) => {
              return accu + prev.orderDetail.shopItemEmbed.priceRebateAgency;
            }, 0)
            .toLocaleString()}
          원
        </p>
        {bugoBrand?.bugoAgency?.shareRebateWithWorker && (
          <>
            <p>
              {filteredShopOrder
                .reduce((accu, prev) => {
                  return accu + (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0);
                }, 0)
                .toLocaleString()}
              원
            </p>
            <p>
              {filteredShopOrder
                .reduce((accu, prev) => {
                  return (
                    accu +
                    prev.orderDetail.shopItemEmbed.priceRebateAgency +
                    (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0)
                  );
                }, 0)
                .toLocaleString()}
              원
            </p>
          </>
        )}
      </div>
    </div>
  );
};
