import { FC, useCallback, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import {
  callOrderBugoIdAtom,
  callOrderShopIdAtom,
} from '@shared/api/callOrder/callOrder.atom';
import { createCallOrder } from '@shared/api/callOrder/callOrder.controller';
import {
  CallOrderCreateDto,
  CallOrderStatus,
} from '@shared/api/callOrder/callOrder.interface';
import { Icon } from '@shared/components/icons';
import { IconFormer } from '@shared/components/IconsFormer';
import { useAuth } from '@shared/state/hooks/useAuth';
import _ from 'lodash';

export const CustomerCallHelperBox: FC = function () {
  const { storeRole } = useAuth();
  const { CustomerCenter } = Icon();
  const bugoId = useRecoilValue(callOrderBugoIdAtom);
  const shopId = useRecoilValue(callOrderShopIdAtom);

  const callOrderCreateDto: CallOrderCreateDto | undefined = useMemo(() => {
    if (_.isUndefined(bugoId) || _.isUndefined(shopId)) return;
    return {
      bugo: bugoId,
      shop: shopId,
      status: CallOrderStatus.Todo,
    };
  }, [bugoId, shopId]);

  const onCallButonClickHandler = useCallback(async () => {
    if (_.isUndefined(callOrderCreateDto)) return;
    try {
      await createCallOrder(callOrderCreateDto, storeRole());
    } catch (err) {
      console.error(err);
    }
  }, [callOrderCreateDto, storeRole]);
  const promotionText = () => {
    const now = new Date();
    if (now.getHours() <= 20) {
      return (
        <p>
          결제가 어려우신가요?{' '}
          <span className="theme-text-main">친절하게 도와드립니다</span>
        </p>
      );
    } else {
      return (
        <p>
          주소 입력 없는 간편한
          <span className="theme-text-main">&nbsp;화환배송</span>
        </p>
      );
    }
  };

  return (
    <div className=" p-4 py-4 theme-bg-1">
      <div className="center-box text-base font-bold">{promotionText()}</div>
      <div className="center-box gap-4 font-bold">
        <div className="center-box flex-col items-center">
          <CustomerCenter />
          <p className="">전화주문 및 안내</p>
          <p className="theme-text-main">오전8시 - 오후8시</p>
        </div>
        <div className="space-y-2">
          <a href={`tel:18006987`} onClick={onCallButonClickHandler}>
            <div className="flex items-center gap-2 text-[21px]">
              <IconFormer.Phone className="wh-5 fill-gray-700" />
              <p>1800-6987</p>
            </div>
          </a>
          <p className="text-xs">클릭 시 상담원에게 바로 연결됩니다</p>
        </div>
      </div>
    </div>
  );
};
