import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';
import _ from 'lodash';

import {
  ShopOrder,
  ShopOrderCancel,
  ShopOrderCreateDto,
  ShopOrderUpdate,
  StoreRole,
} from './shopOrder.interface';

const vaildRolesCreateShopOrder = [LowerRole.StoreCustomer, LowerRole.StoreGuest];

export const createShopOrder = async (
  shopOrderDto: ShopOrderCreateDto,
  storeRole: StoreRole,
) => {
  if (!_.includes(vaildRolesCreateShopOrder, storeRole)) {
    throw new Error(`${storeRole} role can't use this api`);
  } else {
    const postShopOrderResponse = await api_.post<ShopOrder>(
      `/${storeRole}/shop-order/create`,
      shopOrderDto,
    );
    return postShopOrderResponse;
  }
};

export const verifyShopOrderById = async (id: string, storeRole: StoreRole) => {
  if (!_.includes(vaildRolesCreateShopOrder, storeRole)) {
    throw new Error(`${storeRole} role can't use this api`);
  } else {
    const { data: isVerify } = await api_.get<boolean>(
      `${storeRole}/shop-order/success/${id}`,
    );
    return isVerify;
  }
};

export const deleteShopOrderById = async (id: string, storeRole: StoreRole) => {
  if (!_.includes(vaildRolesCreateShopOrder, storeRole)) {
    throw new Error(`${storeRole} role can't use this api`);
  } else {
    return await api_.delete<ShopOrder>(`${storeRole}/shop-order/delete/${id}`);
  }
};

//* shopOrder update
export const updateShopOrder = async (
  shopOrderUpdate: ShopOrderUpdate,
  role: LowerRole,
) => {
  if (role === LowerRole.Admin) {
    const { data } = await api_.patch(
      `/${role}/shop-order/${shopOrderUpdate._id}`,
      shopOrderUpdate,
    );
    return { data };
  } else {
    const { data } = await api_.patch(
      `/${role}/shop-order/patch-detail/${shopOrderUpdate._id}`,
      shopOrderUpdate,
    );
    return { data };
  }
};

export const cancelShopOrder = async (shopOrder: ShopOrderCancel, role: LowerRole) => {
  const { data } = await api_.patch(
    `${role}/shop-order/cancel/${shopOrder._id}`,
    shopOrder,
  );
  return { data };
};

//* store-seller용 전화주문생성
export const createWaitingOrder = async (
  shopOrder: ShopOrderCreateDto,
  role: LowerRole,
) => {
  const { data } = await api_.post<ShopOrder>(
    `${role}/shop-order/create-waiting`,
    shopOrder,
  );
  return data;
};

//* store-seller용 입금완료처리
export const patchOrderWaitingToPaid = async (shopOrderId: string, role: LowerRole) => {
  const { data } = await api_.patch<ShopOrder>(
    `${role}/shop-order/waiting-paid/${shopOrderId}`,
  );
  return data;
};

//*admin 용 shopOrder account처리
export const accountShopOrderList = async (shopOrderIdList: string[]) => {
  const now = new Date();
  const accountUpdateShopOrder = {
    'paymentDetail.isAccounted': true,
    'paymentDetail.accountedDate': now,
  };
  const { data } = await api_.patch<ShopOrder[]>(`admin/shop-order/with-body`, {
    update: accountUpdateShopOrder,
    filter: {
      _id: { $in: shopOrderIdList },
    },
  });
  return data;
};

//*admin 용 shopOrder rebate처리
export const rebateShopOrderList = async (shopOrderIdList: string[]) => {
  const now = new Date();
  const rebateUpdateShopOrder = {
    'paymentDetail.isRebated': true,
    'paymentDetail.rebatedDate': now,
  };
  const { data } = await api_.patch<ShopOrder[]>(`admin/shop-order/with-body`, {
    update: rebateUpdateShopOrder,
    filter: {
      _id: { $in: shopOrderIdList },
    },
  });
  return data;
};
