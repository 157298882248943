import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { ShopOrderForSellerAccount } from '@shared/api/shopOrder/shopOrder.interface';
import { getAccountDate } from '@shared/utils/dateUtil';
import { formatDate } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';

import { SellerAccountDetaiModalButton } from '../SellerAccountDetailModal/SellerAccountDetailModalButton';
import { sellerAccountBugoBrandFilterAtom } from './accountContent.atom';
import { RebateButton } from './RebateButton';

//* 연간 정산 완료 및 예정 내역
interface Props {
  shopOrderList: ShopOrderForSellerAccount[];
}
type AccountData = {
  id: string;
  month: number;
  accountDate?: Date;
  accountPrice: number;
  accountCount: number;
  willAccountCount: number;
  willAccountPrice: number;
  rebatePrice: number;
  willRebatePrice: number;
  accountedOrderList: ShopOrderForSellerAccount[];
  willAccountedOrderList?: ShopOrderForSellerAccount[];
};

const columns: TableColumnsType<AccountData> = [
  {
    title: '정산기간',
    dataIndex: 'month',
    key: 'month',
    align: 'center',
    render: (month: number) => {
      return <p className="px-6 py-1 text-base font-medium">{month}월</p>;
    },
  },
  {
    title: '정산일',
    dataIndex: 'accountDate',
    key: 'accountDate',
    align: 'center',
    render: (_, record) => {
      if (record.accountDate) {
        return (
          <p className="px-6 py-1 text-base font-medium text-green-600">
            {formatDate(record.accountDate, {
              contains: {
                year: false,
              },
            })}{' '}
            완료
          </p>
        );
      } else {
        const accountDate = getAccountDate(record.month);
        const now = new Date();
        if (now.getTime() > accountDate.getTime()) {
          return (
            <p className="px-6 py-1 text-base font-medium theme-text-warn-03">
              {formatDate(getAccountDate(record.month), {
                contains: {
                  year: false,
                },
              })}{' '}
              미지급
            </p>
          );
        } else {
          return (
            <p
              className={`px-6 py-1 text-base font-medium ${
                record.willAccountCount > 0 ? 'theme-text-main' : ''
              }`}
            >
              {formatDate(getAccountDate(record.month), {
                contains: {
                  year: false,
                },
              })}{' '}
              예정
            </p>
          );
        }
      }
    },
  },
  {
    title: '총 판매건수',
    dataIndex: 'accountCount',
    key: 'accountCount',
    align: 'center',
    render: (accountCount: number, record) => {
      const now = new Date();
      if (accountCount === 0 && record.willAccountCount) {
        return (
          <p
            className={`px-6 py-1 text-base font-medium ${
              now.getTime() > getAccountDate(record.month).getTime()
                ? 'theme-text-warn-03'
                : 'theme-text-main'
            }`}
          >
            {record.willAccountCount.toLocaleString()}개
          </p>
        );
      } else {
        return (
          <p className="px-6 py-1 text-base">
            {accountCount === 0 ? '-' : accountCount.toLocaleString() + '개'}
          </p>
        );
      }
    },
  },
  {
    title: '화환 정산금',
    dataIndex: 'accountPrice',
    key: 'accountPrice',
    align: 'center',
    render: (accountPrice: number, record) => {
      const now = new Date();
      if (accountPrice === 0 && record.willAccountPrice) {
        return (
          <p
            className={`px-6 py-1 text-base font-medium ${
              now.getTime() > getAccountDate(record.month).getTime()
                ? 'theme-text-warn-03'
                : 'theme-text-main'
            }`}
          >
            {record.willAccountPrice.toLocaleString()}원
          </p>
        );
      } else {
        return (
          <p className="px-6 py-1 text-base font-medium">
            {accountPrice === 0 ? '-' : accountPrice.toLocaleString() + '원'}
          </p>
        );
      }
    },
  },
  {
    title: '상조 정산금',
    dataIndex: 'rebatePrice',
    key: 'rebatePrice',
    align: 'center',
    render: (rebatePrice: number, record) => {
      const now = new Date();
      if (rebatePrice === 0 && record.willRebatePrice) {
        return (
          <p
            className={`px-6 py-1 text-base font-medium ${
              now.getTime() > getAccountDate(record.month).getTime()
                ? 'theme-text-warn-03'
                : 'theme-text-main'
            }`}
          >
            {record.willRebatePrice.toLocaleString()}원
          </p>
        );
      } else {
        return (
          <p className="px-6 py-1 text-base font-medium">
            {rebatePrice === 0 ? '-' : rebatePrice.toLocaleString() + '원'}
          </p>
        );
      }
    },
  },
  {
    title: '합계',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      const now = new Date();
      if (record.accountCount === 0 && record.willAccountCount) {
        return (
          <p
            className={`px-6 py-1 text-base font-medium ${
              now.getTime() > getAccountDate(record.month).getTime()
                ? 'theme-text-warn-03'
                : 'theme-text-main'
            }`}
          >
            {(record.willAccountPrice + record.willRebatePrice).toLocaleString()}원
          </p>
        );
      } else {
        return (
          <p className="px-6 py-1 text-base font-medium">
            {record.accountPrice + record.rebatePrice === 0
              ? '-'
              : (record.accountPrice + record.rebatePrice).toLocaleString() + '원'}
          </p>
        );
      }
    },
  },
  {
    title: '세부내역보기',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      if (record.accountedOrderList.length === 0 && record.willAccountedOrderList) {
        return (
          <div className="center-box">
            <SellerAccountDetaiModalButton
              shopOrderList={record.willAccountedOrderList}
            />
          </div>
        );
      } else {
        return (
          <div className="center-box">
            <SellerAccountDetaiModalButton shopOrderList={record.accountedOrderList} />
          </div>
        );
      }
    },
  },
  {
    title: '상조정산',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      return <RebateButton shopOrderList={record.accountedOrderList}></RebateButton>;
    },
  },
];

export const AnnualAccountedContent: FC<Props> = function AnnualAccountedContent({
  shopOrderList,
}: Props) {
  //연도 설정

  const now = new Date();
  //TODO: year 설정
  const [selectYear, setSelectYear] = useState<number>(now.getFullYear());

  // 1. 월별로 주문을 분류할 객체를 만듭니다.
  const monthlyAccountedOrders: { [month: number]: ShopOrderForSellerAccount[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyAccountedOrders[i] = [];
  }

  const monthlyWillAccountOrders: { [month: number]: ShopOrderForSellerAccount[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyWillAccountOrders[i] = [];
  }

  // 2. order 배열을 순회하면서, 각 주문의 월을 결정합니다.
  shopOrderList.map((order) => {
    if (order.paymentDetail.isAccounted && order.paymentDetail.requestedAt) {
      const month = order.paymentDetail.requestedAt.getMonth() + 1;
      monthlyAccountedOrders[month]?.push(order);
    } else {
      if (order.paymentDetail.requestedAt) {
        const month = order.paymentDetail.requestedAt.getMonth() + 1;
        monthlyWillAccountOrders[month]?.push(order);
      }
    }
  });

  const resultArray = _.map(monthlyAccountedOrders, (orderList, index) => {
    return {
      accountedOrderList: orderList,
      willAccountedOrderList: monthlyWillAccountOrders[Number(index)],
    };
  });

  // BugoBrand Filtering
  const selectedBugoBrandsTypes = useRecoilValue(sellerAccountBugoBrandFilterAtom);

  const filteredResultArray = resultArray.map((item) => {
    return {
      accountedOrderList: item.accountedOrderList.filter((order) => {
        return selectedBugoBrandsTypes[
          order.bugo?.bugoBrand?.bugoAgency?.nickName ?? 'etc'
        ];
      }),
      willAccountedOrderList: item.willAccountedOrderList?.filter((order) => {
        return selectedBugoBrandsTypes[
          order.bugo?.bugoBrand?.bugoAgency?.nickName ?? 'etc'
        ];
      }),
    };
  });

  const tableData: AccountData[] = useMemo(() => {
    const now = new Date();
    const annualData = _.map(filteredResultArray, (item, index) => {
      return {
        id: `accountTable-${index}`,
        month: index + 1,
        accountDate:
          !(item.willAccountedOrderList && item.willAccountedOrderList.length > 0) &&
          item.accountedOrderList.length === 0 &&
          now.getTime() > getAccountDate(index + 1).getTime()
            ? getAccountDate(index + 1)
            : item.accountedOrderList[0]?.paymentDetail.accountedDate,
        accountCount: item.accountedOrderList.length,
        willAccountCount: item.willAccountedOrderList?.length ?? 0,
        accountPrice:
          item.accountedOrderList.reduce((accu, prev) => {
            return accu + prev.orderDetail.shopItemEmbed.priceWhole;
          }, 0) ?? 0,
        willAccountPrice:
          item.willAccountedOrderList?.reduce((accu, prev) => {
            return accu + prev.orderDetail.shopItemEmbed.priceWhole;
          }, 0) ?? 0,
        rebatePrice: item.accountedOrderList?.reduce((accu, prev) => {
          return accu + prev.orderDetail.shopItemEmbed.priceRebate;
        }, 0),
        willRebatePrice:
          item.willAccountedOrderList?.reduce((accu, prev) => {
            return accu + prev.orderDetail.shopItemEmbed.priceRebate;
          }, 0) ?? 0,
        accountedOrderList: item.accountedOrderList,
        willAccountedOrderList: item.willAccountedOrderList,
      };
    });
    return annualData;
  }, [filteredResultArray]);

  const filteredShopOrder = shopOrderList.filter((order) => {
    return selectedBugoBrandsTypes[order.bugo?.bugoBrand?.bugoAgency?.nickName ?? 'etc'];
  });

  return (
    <div className="space-y-4">
      <p className="pl-4 text-base font-medium">{selectYear}년 정산완료 내역</p>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          defaultPageSize: 13,
          hideOnSinglePage: true,
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
      <div className="button-rectangle flex cursor-default items-center justify-evenly gap-4 text-base theme-bg-9">
        <p>합산</p>
        <p>{filteredShopOrder.length}건</p>
        <p>
          {filteredShopOrder
            .reduce((accu, prev) => {
              return accu + prev.orderDetail.shopItemEmbed.priceWhole;
            }, 0)
            .toLocaleString()}
          원
        </p>
        <p>
          {filteredShopOrder
            .reduce((accu, prev) => {
              return accu + prev.orderDetail.shopItemEmbed.priceRebate;
            }, 0)
            .toLocaleString()}
          원
        </p>
        <p>
          {filteredShopOrder
            .reduce((accu, prev) => {
              return (
                accu +
                prev.orderDetail.shopItemEmbed.priceRebate +
                prev.orderDetail.shopItemEmbed.priceWhole
              );
            }, 0)
            .toLocaleString()}
          원
        </p>
      </div>
    </div>
  );
};
