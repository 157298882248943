import { FC, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { makeOptionList } from '@shared/containers/Select/Select';
import { IAutoCompleteProps } from '@shared/interfaces/autoComplete.interface';
import { ISelectOption } from '@shared/interfaces/select.interface';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { Controller } from 'react-hook-form';
import { useId } from 'react-id-generator';

import { BottomPopup } from '../BottomPopup';
import { TextField } from '../TextField';
// eslint-disable-next-line import/order
import { Icon } from '../icons';

const isTextFieldOption = {
  value: String(Date.now()),
  label: '직접입력',
};

const MobileAutoComplete: FC<IAutoCompleteProps> = ({
  placeholder,
  optionList,
  control,
  name,
  className = '',
  defaultValue = '',
}: IAutoCompleteProps) => {
  const { ChevronDown } = Icon();
  const [id] = useId(1, 'mobileSelect');
  const openId = `mobileAutoComplete-${id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const options = [isTextFieldOption, ...makeOptionList(optionList)];
  const [isTextField, setIsTextField] = useState<boolean>(false);

  const onConfirm = (option: ISelectOption, onChange: (...event: any[]) => void) => {
    if (option.value === isTextFieldOption.value) {
      onChange('');
      setIsTextField(true);
    } else {
      setIsTextField(false);
      // set value in react hook form
      onChange(option.value);
    }
    // close modal
    setOpen(false);
  };

  const closeKeyboardInput = () => {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
  };

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={(
        { field: { onChange, value, onBlur, ref } }, // value: string;
      ) => (
        <>
          {isTextField ? (
            <div className={`${className}`}>
              <TextField
                onChange={(event) => onChange(event.currentTarget.value)}
                value={value}
                ref={ref}
                onBlur={onBlur}
                autoFocus
                placeholder={placeholder}
                className={`${className} w-full pr-10`}
              >
                <button
                  type="button"
                  onClick={() => setOpen(true)}
                  className="absolute top-0 bottom-0 right-0 my-auto pr-1"
                >
                  <ChevronDown />
                </button>
              </TextField>
            </div>
          ) : (
            <div
              className={`${className} select relative flex cursor-pointer items-center justify-between pr-1 text-sm font-bold theme-bg-1 theme-border-1`}
              onClick={() => {
                closeKeyboardInput();
                setOpen(true);
              }}
              ref={ref}
              onBlur={onBlur}
            >
              {value === '' ? (
                <p className="theme-text-6">{placeholder}</p>
              ) : (
                <p>{options.find((option) => option.value === value)?.label ?? value}</p>
              )}
              <div>
                <ChevronDown />
              </div>
            </div>
          )}
          <BottomPopup openId={openId}>
            <div className="w-screen rounded-t-2xl p-6 theme-text-1 theme-bg-1">
              {/* Title */}
              <h4 className="text-center text-sm font-bold">{placeholder}</h4>
              {/* Item List */}
              <ul className="max-h-64 overflow-y-auto pt-3 pb-4">
                {options.map((option) => (
                  <li
                    key={`${option.value}-${option.label}`}
                    onClick={() => onConfirm(option, onChange)}
                    className={`cursor-pointer border-b py-3 text-center leading-5 theme-border-1 ${
                      option.value === value ? 'text-white theme-bg-main' : ''
                    }`}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
          </BottomPopup>
        </>
      )}
    />
  );
};

export default MobileAutoComplete;
