import { FC } from 'react';

import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';

interface IProps {
  dateRange: [any, any];
  onChange: RangePickerProps['onChange'];
  size?: 'small' | 'middle' | 'large';
}

const { RangePicker } = DatePicker;

const DateFilterSearcher: FC<IProps> = ({
  dateRange,
  onChange,
  size = 'middle',
}: IProps) => {
  const onDateChange = (values: [any, any] | null, formatString: [string, string]) => {
    if (onChange && (!values || !values[0] || !values[1])) {
      onChange([null, null], formatString);
      return;
    }

    if (!onChange || !values || !values[0] || !values[1]) return;

    const from = dayjs(values[0]).toDate();
    const to = dayjs(values[1]).toDate();

    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);

    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);

    onChange([dayjs(from), dayjs(to)], formatString);
  };
  return (
    <RangePicker size={size} value={dateRange} onChange={onDateChange} locale={locale} />
  );
};

export default DateFilterSearcher;
